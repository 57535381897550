import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Bolet amb un capell de fins a 15 cm de diàmetre. Primer hemisfèric i posteriorment s’obre. És de color roig molt variable, a vegades el capell pot ser vellutat. Davall té tubs llargs i separables, de color groc olivaci que passa a blau quan es prema amb els dits. Els porus són estrets, quasi arrodonits, vermells taronja primer i després més clar, tornant blaus amb la pressió dels dits. El peu és llis, atenuat a la base i més ample per la meitat. Les espores són de color oliva obscur en massa. Tenen les pareds gruixudes i són el·líptiques o un poc fusiformes, de 10-15 x 5-8 micres.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      